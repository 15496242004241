import React from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import Link from 'next/link';
import LazyLoad from 'react-lazy-load';

import BaseButtonStyle from '../../../../components/Buttons/BaseButtonStyle';
import useQueryParams from '../../../../hooks/useQueryParams';

const StyledWallOfPhotos = styled.div`
  overflow: hidden;
`;

const StyledHeader = styled.div`
  margin-top: 100px;
  margin-bottom: -35px;
`;

const StyledWallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 1090px;

  ${down('sm')} {
    min-height: 1284px;
  }

  ${down('md')} {
    .responsive img {
      display: block;
    }

    .non-responsive img {
      display: none;
    }
  }

  ${up('md')} {
    .responsive img {
      display: none;
    }

    .non-responsive img {
      display: block;
    }
  }
`;

const StyledH3 = styled.h3`
  text-align: center;
  font-size: 20px;
  margin: 40px 0;
  font-weight: 500;

  ${down('sm')} {
    padding: 0 20px;
    margin-bottom: 60px;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: -190px;
  padding-bottom: 60px;

  img {
    height: 120px;
    margin-top: 40px;
  }
`;

const GetStartedButtonContainer = styled.div`
  padding: 40px 0;
  border-top: 1px dashed #dfdfdf;
  border-bottom: 1px dashed #dfdfdf;
  display: flex;
  justify-content: center;

  ${down('md')} {
    margin-top: 0;
  }

  ${down('sm')} {
    width: 100%;
    padding: 20px;
  }
`;

const StyledAnchor = styled.a`
  ${down('sm')} {
    width: 100%;
  }
`;

const StyledLinkButton = styled.button`
  ${BaseButtonStyle};

  font-weight: 500;

  ${down('sm')} {
    width: 100%;
  }
`;

const WallOfPhotos = () => {
  const { query } = useQueryParams();

  return (
    <StyledWallOfPhotos>
      <StyledHeader>
        <StyledH3>Just some gratuitous cuteness overload</StyledH3>
      </StyledHeader>
      <StyledWallContainer>
        <LazyLoad offsetVertical={600}>
          <picture className="non-responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic.jpg')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic.jpg')}
              type="image/jpg"
            />
            <img src={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic.jpg')} />
          </picture>
        </LazyLoad>
        <LazyLoad offsetVertical={600}>
          <picture className="responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic-mobile.jpg')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic-mobile.jpg')}
              type="image/jpg"
            />
            <img src={require('../../../../../public/static/images/home/photo-mosaic/photo-mosaic-mobile.jpg')} />
          </picture>
        </LazyLoad>
      </StyledWallContainer>
      <StyledFooter>
        <StyledH3>Ready to join #thedopplegang? Better than free childcare. :)</StyledH3>
        <GetStartedButtonContainer>
          <Link href={{ pathname: '/quiz', query }}>
            <StyledAnchor>
              <StyledLinkButton>Get Started</StyledLinkButton>
            </StyledAnchor>
          </Link>
        </GetStartedButtonContainer>
        <picture>
          <source
            srcSet={require('../../../../../public/static/images/home/photo-mosaic/homeKid2.png')}
            type="image/webp"
          />
          <source
            srcSet={require('../../../../../public/static/images/home/photo-mosaic/homeKid2.png')}
            type="image/png"
          />
          <img src={require('../../../../../public/static/images/home/photo-mosaic/homeKid2.png')} />
        </picture>
      </StyledFooter>
    </StyledWallOfPhotos>
  );
};

export default WallOfPhotos;
