import React from 'react';
import styled from 'styled-components';

import PageWithAuth from '../../../../components/PageWithAuth';

import HomeHero from '../../components/HomeHero';
import HowItWorks from '../../components/HowItWorks';
import AsSeenIn from '../../components/AsSeenIn';
import WallOfPhotos from '../../components/WallOfPhotos';
import WhyWeMade from '../../components/WhyWeMade';
import Brands from '../../components/Brands';
import TestimonialsCarousel from '../../components/TestimonialsCarousel';
import GetStarted from '../../components/GetStarted';
import AngleSplitter from '../../components/AngleSplitter';
import Instagram from '../../components/Instagram';
import pagesMeta from '../../../../constants/pages-meta';
import TermForSubscribersModal from '../../components/TermForSubscribersModal';

const StyledSplitter = styled(AngleSplitter)`
  margin-top: -200px;
`;

const HomePage = () => (
  <PageWithAuth title={pagesMeta.home.title} description={pagesMeta.home.description}>
    <TermForSubscribersModal />
    <HomeHero />
    <StyledSplitter upperPartTransparent={true} splitterColor="#eaeaea" lowerPartColor="#F5F6FA" />
    <AsSeenIn />
    <HowItWorks />
    <WallOfPhotos />
    <WhyWeMade />
    <Brands />
    <TestimonialsCarousel />
    <Instagram />
    <GetStarted />
  </PageWithAuth>
);

export default HomePage;
