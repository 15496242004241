import React from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { FullsizePicture } from 'react-responsive-picture';
import LazyLoad from 'react-lazy-load';

import Squiggly from '../Squiggly';

const StyledSquiggly = styled(Squiggly)`
  margin-bottom: 2rem;
`;

const StyledWhyWeMade = styled.div`
  height: 760px;
  width: 100%;

  ${up('xxl')} {
    height: 930px;
  }

  ${down('sm')} {
    height: 930px;
  }

  picture img {
    z-index: -5;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 120px 0;

  ${down('md')} {
    padding: 120px 20px 0;
  }
`;

const StyledH3 = styled.h3`
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  margin: 20px 0 3rem;

  ${down('md')} {
    font-size: 1.5rem;
  }
`;

const StyledH6 = styled.h6`
  text-align: center;
  color: white;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 500;

  max-width: 700px;
  line-height: 1.6;

  ${down('md')} {
    font-size: 1rem;
  }
`;

const WhyWeMade = () => (
  <LazyLoad offsetVertical={600}>
    <StyledWhyWeMade>
      <FullsizePicture
        src={require('../../../../../public/static/images/home/why-we-made/background-section-2.png')}
        sources={[
          {
            srcSet: require('../../../../../public/static/images/home/why-we-made/background-section-2.png'),
            type: 'image/webp',
          },
        ]}
      >
        <TextContainer>
          <StyledH3>Why we made Dopple</StyledH3>
          <StyledSquiggly />
          <StyledH6>
            We created Dopple to make parents&apos; lives just a little more sane and a lot more fun, to save you time
            and money, and to help you feel like the cool parent you are. Raising kids is freaking hard -- oh,
            that&apos;s why we affectionately call them little monsters -- so we&apos;re taking shopping for kids
            clothes off your overloaded, sleep-deprived plate.
            <br />
            <br />
            Free shipping and free returns, always!
          </StyledH6>
        </TextContainer>
      </FullsizePicture>
    </StyledWhyWeMade>
  </LazyLoad>
);

export default WhyWeMade;
