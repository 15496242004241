import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { FullsizePicture } from 'react-responsive-picture';
import LazyLoad from 'react-lazy-load';

import Squiggly from '../Squiggly';

import BaseButtonStyle from '../../../../components/Buttons/BaseButtonStyle';
import useQueryParams from '../../../../hooks/useQueryParams';

const StyledGetStarted = styled.div`
  height: 600px;

  picture img {
    z-index: -5;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledGetStartedOutsideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const StyledGetStartedInsideContainer = styled.div`
  border-radius: 4px;
  box-shadow: -10px 2px 14px 0 rgba(215, 215, 215, 0.25);
  background-color: #fff;
  padding: 53px 40px;
  width: 660px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  ${down('md')} {
    width: 95%;
  }
`;

const StyledGetStartedShadow = styled.div`
  position: absolute;
  width: 660px;
  margin-top: 30px;
  margin-left: 20px;
  height: 316px;
  background-color: rgba(255, 231, 76, 0.35);
  border-radius: 4px;
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -o-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  transform: rotate(-1deg);
  z-index: 1;

  ${down('md')} {
    width: 97%;
    margin-left: 0;
    margin-top: 3rem;
  }

  ${down('sm')} {
    margin-top: 4.5rem;
  }
`;

const StyledSquiggly = styled(Squiggly)`
  margin-bottom: 30px;
`;

const StyledAnchor = styled.a`
  ${down('xs')} {
    width: 100%;
  }
`;

const StyledLinkButton = styled.button`
  ${BaseButtonStyle};

  ${down('xs')} {
    width: 100%;
  }
`;

const GetStarted = () => {
  const { query } = useQueryParams();

  return (
    <LazyLoad offsetVertical={600}>
      <StyledGetStarted>
        <FullsizePicture
          src={require('../../../../../public/static/images/home/get-started/get-started-background.png')}
          sources={[
            {
              srcSet: require('../../../../../public/static/images/home/get-started/get-started-background.png'),
              type: 'image/webp',
            },
          ]}
        >
          <StyledGetStartedOutsideContainer>
            <StyledGetStartedInsideContainer>
              <StyledH3>Parenting is hard….at least dressing your little monsters is easy with Dopple!</StyledH3>
              <StyledSquiggly />
              <Link href={{ pathname: '/quiz', query }}>
                <StyledAnchor>
                  <StyledLinkButton>Get Started</StyledLinkButton>
                </StyledAnchor>
              </Link>
            </StyledGetStartedInsideContainer>
            <StyledGetStartedShadow />
          </StyledGetStartedOutsideContainer>
        </FullsizePicture>
      </StyledGetStarted>
    </LazyLoad>
  );
};

export default GetStarted;
