import React, { useEffect } from 'react';
import styled from 'styled-components';
import Instafeed from 'instafeed.js';
import useSWR from 'swr';
import fetch from '../../../../utils/fetch';
import Squiggly from '../Squiggly';
import { FullsizePicture } from 'react-responsive-picture';
import { up, down } from 'styled-breakpoints';

const StyledFeed = styled.div`
  width: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7f7;
  padding-bottom: 80px;
`;

const StyledImageContainer = styled.div`
  height: 300px;
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const InstagramGrid = styled.div`
  #instafeed {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80%;
    padding-bottom: 30px;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 25vh;
    object-fit: cover;
    object-position: top;
  }

  a {
    height: 25vh;
    width: 100%;
  }

  .overlay {
    height: auto;
    width: 100%;
    opacity: 1;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  .caption-text {
    text-align: center;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-0%, -100%);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    overflow: hidden;
  }

  .caption-text:hover {
    background: rgba(250, 250, 250, 0.6);
    top: 50%;
    left: 50%;
    height: 100%;
    opacity: 1;
    margin-top: -2px;
    padding: 15px;
  }

  ${up('xxl')} {
    a {
      height: 25vh;
      width: 100%;
    }

    img {
      max-height: 25vh;
    }

    .caption-text:hover {
      background: rgba(250, 250, 250, 0.6);
      top: 50%;
      left: 50%;
      height: 98%;
      opacity: 1;
    }
  }

  @media screen and (max-width: 1800px) {
    a {
      height: 24vh;
      width: 100%;
    }

    img {
      max-height: 24vh;
    }
  }

  @media screen and (max-width: 1700px) {
    a {
      height: 23vh;
      width: 100%;
    }

    img {
      max-height: 23vh;
    }
  }

  @media screen and (max-width: 1600px) {
    a {
      height: 22vh;
      width: 100%;
    }

    img {
      max-height: 22vh;
    }
  }

  @media screen and (max-width: 1500px) {
    a {
      height: 21vh;
      width: 100%;
    }

    img {
      max-height: 21vh;
    }
  }

  @media screen and (max-width: 1400px) {
    a {
      height: 20vh;
      width: 100%;
    }

    img {
      max-height: 20vh;
    }
  }

  @media screen and (max-width: 1300px) {
    a {
      height: 19vh;
      width: 100%;
    }

    img {
      max-height: 19vh;
    }
  }

  @media screen and (max-width: 1200px) {
    a {
      height: 18vh;
      width: 100%;
    }

    img {
      max-height: 18vh;
    }
  }

  @media screen and (max-width: 1100px) {
    a {
      height: 15vh;
      width: 15vh;
    }

    img {
      max-height: 15vh;
    }
  }

  ${down('md')} {
    #instafeed {
      display: grid;
      // grid-gap: 20px;
      grid-template-columns: auto auto auto;
      grid-template-rows: 45% 45%;
      padding-bottom: 28px;
    }

    a {
      height: 16vh;
      width: 16vh;
    }

    img {
      max-height: 16vh;
    }

    .caption-text:hover {
      background: rgba(250, 250, 250, 0.6);
      top: 50%;
      left: 50%;
      height: 100%;
      opacity: 1;
    }
  }

  ${down('sm')} {
    #instafeed {
      display: grid;
      // grid-gap: 20px;
      grid-template-columns: auto auto auto;
      grid-template-rows: 45% 45%;
      padding-bottom: 28px;
    }

    img {
      max-height: 14vh;
    }

    a {
      width: 14vh;
      height: 14vh;
    }

    .caption-text:hover {
      background: rgba(250, 250, 250, 0.6);
      top: 50%;
      left: 50%;
      height: 101%;
      opacity: 1;
    }
  }

  ${down('xs')} {
    img {
      height: auto;
      max-height: 9vh;
    }

    img:hover + #caption-text {
      display: none;
    }

    a {
      width: 9vh;
      height: 9vh;
    }
  }
`;

type InstagramFeedProps = {
  token: string;
};

function InstagramFeed({ token }: InstagramFeedProps) {
  useEffect(() => {
    const feed = new Instafeed({
      accessToken: token,
      limit: 6,
      template:
        '<a href="{{link}}" target="_blank"><div class="overlay"><img src="{{image}}" id="{{id}}" time="{{model.timestamp}}" /></div><div class="caption-text"><p>{{caption}}</p></div></a>',
      transform: function(item: any) {
        const d = new Date(item.timestamp);
        item.date = [d.getDate(), d.getMonth(), d.getFullYear()].join('/');
        return item;
      },
    });
    feed.run();
  }, []);

  return (
    <InstagramGrid>
      <div id="instafeed"></div>
    </InstagramGrid>
  );
}

type InstagramTokenAPIResponse = {
  token: string;
};

function InstagramFeedContainer() {
  const { data } = useSWR<InstagramTokenAPIResponse>('https://instagram-token-agent.thedopple.com/token.json', fetch);
  if (!data || !data.token) return null;
  return <InstagramFeed token={data.token} />;
}

const Instagram = () => {
  return (
    <StyledFeed>
      <StyledImageContainer>
        <FullsizePicture
          cover="height"
          src={require('../../../../../public/static/images/home/instagram/instagram-background.png')}
          sources={[
            {
              srcSet: require('../../../../../public/static/images/home/instagram/instagram-background.png'),
              type: 'image/webp',
            },
          ]}
        />
      </StyledImageContainer>
      <StyledHeader>
        <InstagramFeedContainer />
        <Squiggly />
      </StyledHeader>
    </StyledFeed>
  );
};

export default Instagram;
