import React from 'react';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import Link from 'next/link';

import BaseButtonStyle from '../../../../components/Buttons/BaseButtonStyle';
import useQueryParams from '../../../../hooks/useQueryParams';

const StyledHowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f6fa;
`;

const StyledHeader = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const StyledStepHeader = styled.h2`
  font-size: 2.5rem;
  font-family: 'KitsuneUdon';
  font-weight: 400;
  color: #2b3238;
  text-transform: capitalize;
  margin-bottom: 2rem;
`;

const StyledStepSubHeader = styled.h5`
  font-weight: 500;
  margin-bottom: 1.4rem;
  color: #20435c;
`;

const StyledStepLead = styled.p`
  font-weight: 100;
  color: #7a7a80;
  line-height: 1.64;
  margin-bottom: 2rem;
  text-align: center;
`;

const StyledStepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  width: 900px;

  ${down('lg')} {
    width: 100%;
    padding: 0;
  }

  ${down('sm')} {
    padding: 0;
  }

  ${down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledStepColumn = styled.div`
  width: 32%;
  height: auto;
  margin: 0 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${down('sm')} {
    width: 100%;
    padding: 0 20px;
    margin: 20px 0 0;
  }

  img {
    height: 300px;
  }

  &.push-down {
    padding-top: 80px;

    ${down('sm')} {
      padding-top: 0;
    }
  }

  ${down('lg')} {
    .responsive img {
      display: block;
    }

    .non-responsive img {
      display: none;
    }
  }

  ${up('lg')} {
    .responsive img {
      display: none;
    }

    .non-responsive img {
      display: block;
    }
  }
`;

const GetStartedButtonContainer = styled.div`
  padding: 40px 0;
  border-top: 1px dashed #dfdfdf;
  border-bottom: 1px dashed #dfdfdf;
  margin-top: -80px;
  display: flex;
  justify-content: center;

  ${down('md')} {
    margin-top: 0;
  }

  ${down('sm')} {
    width: 100%;
    padding: 20px;
  }
`;

const StyledAnchor = styled.a`
  ${down('sm')} {
    width: 100%;
  }
`;

const StyledLinkButton = styled.button`
  ${BaseButtonStyle};

  font-weight: 500;

  ${down('sm')} {
    width: 100%;
  }
`;

const FooterDoodleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: -80px;
`;

const HowItWorks = () => {
  const { query } = useQueryParams();

  return (
    <StyledHowItWorks>
      <StyledHeader>How it works</StyledHeader>
      <StyledStepsContainer>
        <StyledStepColumn className="push-down">
          <picture className="responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')} />
          </picture>
          <StyledStepHeader>Step 1:</StyledStepHeader>
          <StyledStepSubHeader>Take our quick questionnaire</StyledStepSubHeader>
          <StyledStepLead>
            Tell us about your fab style, budget, kiddo’s size and what you items you want.
          </StyledStepLead>
          <picture className="non-responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step1.png')} />
          </picture>
        </StyledStepColumn>

        <StyledStepColumn>
          <picture className="non-responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2.png')} />
          </picture>
          <picture className="responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2-mobile.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2-mobile.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step2-mobile.png')} />
          </picture>
          <StyledStepHeader>Step 2:</StyledStepHeader>
          <StyledStepSubHeader>Receive your Drop (of dopamine)</StyledStepSubHeader>
          <StyledStepLead>Obsess over the cutest outfits we put together just for your kiddos!</StyledStepLead>
        </StyledStepColumn>

        <StyledStepColumn className="push-down">
          <picture className="responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3-mobile.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3-mobile.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3-mobile.png')} />
          </picture>
          <StyledStepHeader>Step 3:</StyledStepHeader>
          <StyledStepSubHeader>Keep what you love</StyledStepSubHeader>
          <StyledStepLead>
            Only pay for what you keep and send back the rest. Free shipping, both ways, always!
          </StyledStepLead>
          <picture className="non-responsive">
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3.png')}
              type="image/webp"
            />
            <source
              srcSet={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3.png')}
              type="image/png"
            />
            <img src={require('../../../../../public/static/images/home/how-it-works/how-it-works-step3.png')} />
          </picture>
        </StyledStepColumn>
      </StyledStepsContainer>
      <GetStartedButtonContainer>
        <Link href={{ pathname: '/quiz', query }}>
          <StyledAnchor>
            <StyledLinkButton>Get Started</StyledLinkButton>
          </StyledAnchor>
        </Link>
      </GetStartedButtonContainer>
      <FooterDoodleContainer>
        <picture>
          <source
            srcSet={require('../../../../../public/static/images/home/how-it-works/doodle-01.png')}
            type="image/webp"
          />
          <source
            srcSet={require('../../../../../public/static/images/home/how-it-works/doodle-01.png')}
            type="image/png"
          />
          <img src={require('../../../../../public/static/images/home/how-it-works/doodle-01.png')} />
        </picture>
      </FooterDoodleContainer>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
