import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &.angles-splitter {
  }

  &.flip-angles-splitter {
    margin-top: -14px;
    -moz-transform: scale(-1);
    -o-transform: scale(-1);
    -webkit-transform: scale(-1);
    transform: scale(-1);
    filter: 'FlipH';
    -ms-filter: 'FlipH';
  }
`;

type Props = {
  upperPartColor?: string;
  lowerPartColor?: string;
  splitterColor?: string;
  upperPartTransparent?: boolean;
  lowerPartTransparent?: boolean;
  flip?: boolean;
  className?: string;
};

const AnglesSplitter = ({
  upperPartColor = '#ffffff',
  lowerPartColor = '#ffffff',
  splitterColor = '#ffffff',
  upperPartTransparent = false,
  lowerPartTransparent = false,
  flip = false,
  className = '',
}: Props) => {
  const flipClass = !flip ? 'angles-splitter' : 'flip-angles-splitter';

  return (
    <StyledSvg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="18 0 600 70" className={`${className} ${flipClass}`}>
      <defs>
        <path d="M620.09 15.04L219.76 55.1l-56.07-25.15L18.02 48.81v18.37h602.07V15.04z" id="a" />
        <path d="M18.02 49.55V.95L620.09.94v20.39l-391.3 37.63-65.85-24.9L18.02 49.55z" id="b" />
        <path
          d="M218.49 67.18l401.6-35.19V.95l-401.6 47.22-55.31-24.27L18.02
          40.08V55.7l145.16-16.18 55.31 27.66z"
          id="c"
        />
      </defs>
      {!lowerPartTransparent ? (
        <g>
          <use xlinkHref="#a" fill={lowerPartColor} />
          <use xlinkHref="#a" fillOpacity={0} stroke="#000" strokeWidth={0} />
        </g>
      ) : null}
      {!upperPartTransparent ? (
        <g>
          <use xlinkHref="#b" fill={upperPartColor} />
          <use xlinkHref="#b" fillOpacity={0} stroke="#000" strokeWidth={0} />
        </g>
      ) : null}
      <g>
        <use xlinkHref="#c" fill={splitterColor} />
        <use xlinkHref="#c" fillOpacity={0} stroke="#000" strokeWidth={0} />
      </g>
    </StyledSvg>
  );
};

export default AnglesSplitter;
