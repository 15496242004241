import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { FullsizePicture } from 'react-responsive-picture';

import BaseButtonStyle from '../../../../components/Buttons/BaseButtonStyle';
import useQueryParams from '../../../../hooks/useQueryParams';

const StyledHomeHero = styled.div`
  height: 700px;
  width: 100%;
  z-index: 0;

  img {
    z-index: -5;
  }

  ${up('xxl')} {
    height: 900px;
  }

  ${down('lg')} {
    height: 600px;
  }

  ${down('md')} {
    height: 500px;
  }

  ${down('sm')} {
    height: 600px;
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0 120px;

  ${up('xxl')} {
    padding: 0 300px;
  }

  ${down('md')} {
    padding: 0 100px;
  }

  ${down('sm')} {
    padding: 0 20px;
  }
`;

const StyledH1 = styled.h3.attrs({ className: 'heading-l' })`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.colors.black};

  ${down('sm')} {
    color: white;
    margin-bottom: 2.7rem;
  }
`;

const StyledH5 = styled.h5`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  font-weight: 500;
  width: 400px;
  line-height: 26px;

  ${down('xs')} {
    width: 100%;
  }

  ${down('sm')} {
    color: white;
    font-size: 1.2rem;
  }
`;

const StyledAnchor = styled.a`
  ${down('xs')} {
    width: 100%;
  }
`;

const StyledLinkButton = styled.button`
  ${BaseButtonStyle};

  font-weight: 500;

  ${down('xs')} {
    width: 100%;
  }
`;

const HomeHero = () => {
  const { query } = useQueryParams();

  return (
    <StyledHomeHero>
      <FullsizePicture
        cover="height"
        src={require('../../../../../public/static/images/home/hero/hero-image.jpg')}
        sources={[
          {
            srcSet: require('../../../../../public/static/images/home/hero/hero-image.jpg'),
            type: 'image/webp',
          },
        ]}
      >
        <Overlay>
          <StyledH1>Secret’s out.</StyledH1>
          <StyledH5>
            Dress your little monsters in the coolest styles from hundreds of the best brands, delivered to your door.
          </StyledH5>
          <Link href={{ pathname: '/quiz', query }}>
            <StyledAnchor>
              <StyledLinkButton>Get Started</StyledLinkButton>
            </StyledAnchor>
          </Link>
        </Overlay>
      </FullsizePicture>
    </StyledHomeHero>
  );
};

export default HomeHero;
