import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const IMAGES = [
  {
    webp: require('../../../../../public/static/images/home/brands/misha-puff.png'),
    fallback: require('../../../../../public/static/images/home/brands/misha-puff.png'),
    name: 'misha-puff',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/oeuf.png'),
    fallback: require('../../../../../public/static/images/home/brands/oeuf.png'),
    name: 'oeuf',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/boy-girl.png'),
    fallback: require('../../../../../public/static/images/home/brands/boy-girl.png'),
    name: 'boy-girl',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/rylee-cru.png'),
    fallback: require('../../../../../public/static/images/home/brands/rylee-cru.png'),
    name: 'rylee-cru',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/gray-label.png'),
    fallback: require('../../../../../public/static/images/home/brands/gray-label.png'),
    name: 'gray-label',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/appaman.png'),
    fallback: require('../../../../../public/static/images/home/brands/appaman.png'),
    name: 'appaman',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/soor-ploom.png'),
    fallback: require('../../../../../public/static/images/home/brands/soor-ploom.png'),
    name: 'soor-ploom',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/beau-loves.png'),
    fallback: require('../../../../../public/static/images/home/brands/beau-loves.png'),
    name: 'beau-loves',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/stella-mccartney.png'),
    fallback: require('../../../../../public/static/images/home/brands/stella-mccartney.png'),
    name: 'stella-mccartney',
  },
  {
    webp: require('../../../../../public/static/images/home/brands/chloe.png'),
    fallback: require('../../../../../public/static/images/home/brands/chloe.png'),
    name: 'chloe',
  },
];

const BrandLogo = ({ webp, fallback, ...props }) => (
  <picture {...props}>
    <source srcSet={webp} type="image/webp" />
    <source srcSet={fallback} type="image/png" />
    <img src={fallback} />
  </picture>
);

const StyledBrandLogo = styled(BrandLogo)`
  img {
    margin: 20px;

    ${down('sm')} {
      flex: 1 1 130px;
      width: 130px;
      margin: 4px;
    }
  }
`;

const StyledBrandsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
`;

const StyledBrands = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 60px;

  ${down('md')} {
    padding-bottom: 20px;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: 500;
  text-align: center;
`;

const Brands = () => (
  <StyledBrands>
    <StyledH3>Hundreds of Brands on Board</StyledH3>
    <StyledBrandsContainer>
      {IMAGES.map(({ name, webp, fallback }) => (
        <StyledBrandLogo key={name} webp={webp} fallback={fallback} />
      ))}
    </StyledBrandsContainer>
  </StyledBrands>
);

export default Brands;
