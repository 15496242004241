import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { useUser, updateUser } from '../../../../utils/auth-client';
import Alert, { AlertType } from '../../../../components/Alert';
import loader from '../../../../../public/static/images/loader.svg';
import { NavButton } from '../../../../components/Buttons';
import Link from 'next/link';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(166, 179, 193, 0.9);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const CloseIcon = styled.div`
  font-weight: bold;
  cursor: pointer;
`;

const Modal = styled.div`
  width: 35%;
  padding: 0 1rem 2rem 1rem;
  border-radius: 4px;
  box-shadow: 0 12px 26px 0 rgba(20, 20, 20, 0.1);
  background-color: ${({ theme }) => theme.colors.white};

  ${down('sm')} {
    width: 95%;
  }

  h3 {
    color: rgb(68, 68, 68);
    line-height: 1.4 !important;
    font-size: 21px !important;
    font-weight: 400;
    letter-spacing: 0.1em;
    text-transform: uppercase !important;
    padding: 10px 10px 10px 0;
    width: 100%;

    ${down('sm')} {
      font-size: 1.2rem !important;
    }
  }

  p {
    text-align: justify;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > button {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 10px 0 40px 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  ${down('sm')} {
    width: 100%;
  }
`;

const StyledImage = styled.img`
  border-radius: 50%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = styled(NavButton)`
  width: 100px;
  height: 45px;
  padding: 0;
  align-items: center;
`;

const TermForSubscribersModal = () => {
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useUser();

  useEffect(() => {
    if (currentUser && !currentUser?.has_accepted_terms_conditions) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [currentUser, setShow]);

  const handleAcceptedTermsConditions = useCallback(() => {
    setLoading(true);
    updateUser({ ...currentUser, has_accepted_terms_conditions: true })
      .then(() => {
        setShow(false);
        setError(false);
      })
      .catch(err => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [updateUser, setShow, setError, setLoading]);

  const toggleModal = useCallback(() => setShow(prev => !prev), []);

  if (!show) return null;

  return (
    <Wrapper>
      <Modal>
        <Header>
          <h3>Terms of Service Update</h3>
          <CloseIcon onClick={toggleModal}>X</CloseIcon>
        </Header>
        <Container>
          <p>
            As part of our ongoing efforts to provide the best service to our users, we regularly review our legal
            terms, conditions and policies. We have updated our{' '}
            <Link href="/terms" as="/terms">
              <a>Terms of Service</a>
            </Link>{' '}
            and{' '}
            <Link href="/privacy-policy" as="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>{' '}
            to clarify how we collect and process your information, By continuing to use Dopple's services, you
            acknowledge these changes to our Terms of Service and Privacy Policy. If you have any questions, we are
            happy to help. Please get in touch by emailing{' '}
            <a href="mailto:parentals@thdopple.com">parentals@thdopple.com</a>.
          </p>

          {error && (
            <Alert type={AlertType.ERROR} style={{ margin: '32px 0 0 0' }}>
              Oops, something went wrong!!!
            </Alert>
          )}
        </Container>
        <Footer>
          <Button isValid={true} disabled={loading} onClick={handleAcceptedTermsConditions}>
            {loading ? <StyledImage src={loader} /> : 'Agreed'}
          </Button>
        </Footer>
      </Modal>
    </Wrapper>
  );
};

export default memo(TermForSubscribersModal);
