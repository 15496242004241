import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { publications } from '../../../../constants/publications';

import Squiggly from '../Squiggly';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #f5f6fa;
  padding-top: 120px;
  margin-top: -20px;
  padding-bottom: 80px;
`;

const StyledH3 = styled.h3`
  font-size: 1.5rem;
  font-weight: bolder;
  color: #20435c;
  margin-bottom: 1.5rem;
`;

type LogoProps = {
  image: string;
  webp: string;
  url: string;
};

const LogoLink = ({ image, webp, url, ...props }: LogoProps) => (
  <a href={url} target="_blank" rel="noopener noreferrer" {...props}>
    <picture>
      <source srcSet={webp} type="image/webp" />
      <source srcSet={image} type="image/png" />
      <img src={image} />
    </picture>
  </a>
);

const StyledLogoLink = styled(LogoLink)`
  flex: 0 0 20%;
  height: 70px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    height: 70px;
  }

  ${down('md')} {
    flex: 0 0 40%;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  max-width: 900px;

  ${down('lg')} {
    justify-content: center;
  }
`;

const AsSeenIn = () => (
  <StyledContainer>
    <StyledH3>As seen in</StyledH3>
    <LogosContainer>
      {publications.map(pub => (
        <StyledLogoLink key={pub.key} {...pub} />
      ))}
    </LogosContainer>
    <Squiggly />
  </StyledContainer>
);

export default AsSeenIn;
