export type Publication = {
  key: string;
  url: string | null;
  image: string;
  webp: string;
};

export const publications: Publication[] = [
  {
    image: require('../../public/static/images/logos/popsugar.png'),
    webp: require('../../public/static/images/logos/popsugar.png'),
    key: 'popsugar',
    url: 'https://www.popsugar.com/family/Jamie-Lynn-Spears-Good-Parenting-Despite-Being-Young-45953932',
  },
  {
    image: require('../../public/static/images/logos/people.png'),
    webp: require('../../public/static/images/logos/people.png'),
    key: 'people',
    url: 'https://people.com/parents/jamie-lynn-spears-talks-young-motherhood-daughters/',
  },
  {
    image: require('../../public/static/images/logos/forbes.png'),
    webp: require('../../public/static/images/logos/forbes.png'),
    key: 'forbes',
    url:
      'https://www.forbes.com/sites/carriekerpen/2018/10/02/what-its-really-like-to-be-an-entrepreneur/#4f0700676ef4',
  },
  {
    image: require('../../public/static/images/logos/goop.png'),
    webp: require('../../public/static/images/logos/goop.png'),
    key: 'goop',
    url:
      'https://goop.com/style/trends/everyones-favorite-sparkling-water-face-kneading-and-13-other-things-were-talking-about/',
  },
  {
    image: require('../../public/static/images/logos/mother-magazine.png'),
    webp: require('../../public/static/images/logos/mother-magazine.png'),
    key: 'mother',
    url: 'http://www.mothermag.com/the-dopple/',
  },
  {
    image: require('../../public/static/images/logos/hamptons-magazine.png'),
    webp: require('../../public/static/images/logos/hamptons-magazine.png'),
    key: 'hamptons',
    url: 'https://hamptons-magazine.com/dopple-delivery-to-your-door',
  },
  {
    image: require('../../public/static/images/logos/new-york-post.png'),
    webp: require('../../public/static/images/logos/new-york-post.png'),
    key: 'newyorkpost',
    url: null,
  },
  {
    image: require('../../public/static/images/logos/moneyish.png'),
    webp: require('../../public/static/images/logos/moneyish.png'),
    key: 'moneyish',
    url: null,
  },
];
