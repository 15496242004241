import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Carousel } from 'react-responsive-carousel';

import KateWolfsonImage from '../../../../../public/static/images/home/carousel/kate-wolfson-avatar.png';
import CarouselRightButton from '../../../../../public/static/images/home/carousel/carousel-right-btn.png';
import CarouselLeftButton from '../../../../../public/static/images/home/carousel/carousel-left-btn.png';

const StyledCarousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px 0;
  margin-bottom: 3rem;
`;

const StyledTestimonialsSlider = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;

  ${down('lg')} {
    width: 100%;
    padding: 0 10px;
  }

  .carousel {
    .slider-wrapper {
      padding: 0 40px;

      ${down('md')} {
        padding: 0;
      }
    }

    .slide {
      background: none;
      text-align: center;
      padding: 0 40px;

      ${down('md')} {
        padding: 0;
      }
    }

    .control-arrow,
    &.carousel-slider .control-arrow {
      opacity: 1;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 38px;
      height: 38px;
      top: 50%;

      &:before {
        border: none;
        content: none;
      }

      &:hover {
        background-color: transparent;
      }

      ${down('md')} {
        display: none;
      }
    }

    .control-next.control-arrow {
      background-image: url(${CarouselRightButton});
    }

    .control-prev.control-arrow {
      background-image: url(${CarouselLeftButton});
    }
  }
`;

const StyledTestimonialSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.testimonial-kate-wolfson {
    .avatar {
      background-image: url(${KateWolfsonImage});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100px;
      height: 100px;
      margin-top: 10px;
    }
  }
`;

const StyledH3 = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const StyledTextContent = styled.h5`
  line-height: 1.64;
  color: #686868;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const StyledAuthor = styled.p`
  color: black;
  margin: 10px 0 0;
  font-size: 13px;
  font-weight: bold;
`;

const StyledAuthorTitle = styled.p`
  color: black;
  margin: 0;
  font-size: 13px;
`;

const TestimonialsCarousel = () => (
  <StyledCarousel>
    <StyledH3>Word on the street</StyledH3>
    <StyledTestimonialsSlider>
      <Carousel showThumbs={false} showStatus={false} showIndicators={false} dynamicHeight={true}>
        <StyledTestimonialSlide className="testimonial-kate-wolfson">
          <StyledTextContent>
            “Shopping for my little boy is really fun for me. The problem is that it can get pricey, and there are not
            enough hours in the day to comparison shop and, say, shower, especially because he’s into the more obscure
            brands like Nico Nico, Misha + Puff, Tiny Cottons, and such. (He doesn’t actually care—he doesn’t even know
            what pants are—but I do!) Dopple is a new subscription clothing box that caters to kids age zero to
            fourteen. And the roster of brands includes all the ones I love, plus Oeuf, Winter Water Factory, and more.
            You can choose to send back whatever you don’t want, and whatever you keep, you get at a significant
            discount.”
          </StyledTextContent>
          <StyledAuthor>Kate Wolfson</StyledAuthor>
          <StyledAuthorTitle>Executive Editor, GOOP</StyledAuthorTitle>
          <div className="avatar" />
        </StyledTestimonialSlide>
        <StyledTestimonialSlide>
          <StyledTextContent>
            “If you haven’t heard of The Dopple yet, listen up! The brand-new clothing delivery service offers
            personalized kidswear bundles featuring fancy designer and indie brands that so many of us love (Misha +
            Puff, Oeuf, Gray Label, Petit Bateau, Nico Nico, Rylee + Cru, etc.) at 30% plus off regular retail prices.”
          </StyledTextContent>
          <StyledAuthor>Katie Hintz-Zambrano</StyledAuthor>
          <StyledAuthorTitle>Mother Mag</StyledAuthorTitle>
        </StyledTestimonialSlide>
        <StyledTestimonialSlide>
          <StyledTextContent>
            “Dopple has all my favorite European kids brands for half the price, plus hard to find brands and new
            brands. My friends always ask where my toddler gets his clothes -- now he looks great even when I’m a hot
            mess!”
          </StyledTextContent>
          <StyledAuthor>Eleanor Lim (cool mom of one adorable monster)</StyledAuthor>
          <StyledAuthorTitle>Redwood City, CA</StyledAuthorTitle>
        </StyledTestimonialSlide>
        <StyledTestimonialSlide>
          <StyledTextContent>
            “Dopple is a no brainer -- I filled out the quiz while I was in line for coffee and now my little (hipster)
            guy has cool clothes that I didn’t have to spend any time to buy. We kept everything in the box and already
            requested another one for the Spring. Lifesaver for parents, and my son loves everything I put him in!”
          </StyledTextContent>
          <StyledAuthor>Justin Smith (Cool dad of a two year old monster)</StyledAuthor>
          <StyledAuthorTitle>Palo Alto, CA</StyledAuthorTitle>
        </StyledTestimonialSlide>
      </Carousel>
    </StyledTestimonialsSlider>
  </StyledCarousel>
);

export default TestimonialsCarousel;
